import {
  Injectable, Output, EventEmitter,

} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints
} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

 @Output() breakpointUpdate= new EventEmitter<boolean>();
  public isSmallScreen = false;// = this.breakpointObserver.isMatched([Breakpoints.Handset, Breakpoints.Small, Breakpoints.XSmall]);
 public screenSize = "";

 // firstLoad = true;
  constructor(private breakpointObserver: BreakpointObserver) {
    //console.log("BreakpointObserver > constructor")
//    console.log("New Breakpoint Service. This is a singleton and loaded from the app.module. It is avaiable through dependency injection in all components, but only runs one instance.");
    this.breakpointObserver.observe([
      Breakpoints.Handset, 
      Breakpoints.Small, 
      Breakpoints.XSmall, 
      Breakpoints.Medium, 
      Breakpoints.Large
    ]).subscribe(result => {      
     // console.log("bps result", result)
    //  console.log({
    //    "Breakpoints.Handset":result.breakpoints[Breakpoints.Handset],
    //    "Breakpoints.Small":result.breakpoints[Breakpoints.Small],
    //    "Breakpoints.XSmall":result.breakpoints[Breakpoints.XSmall],
    //    "Breakpoints.Medium":result.breakpoints[Breakpoints.Medium],
    //    "Breakpoints.WebPortrait":result.breakpoints[Breakpoints.WebPortrait],
    //    "Breakpoints.Large":result.breakpoints[Breakpoints.Large],
    //    "Breakpoints.XLarge":result.breakpoints[Breakpoints.XLarge],
     
     
    //   })
      if (result.matches) {
        if(
          result.breakpoints[Breakpoints.Handset]         
          || result.breakpoints[Breakpoints.XSmall]
          )
          {
            this.screenSize = 'xsmall' 
            this.isSmallScreen = true;     
          }
          else if(result.breakpoints[Breakpoints.Small] ){
            this.screenSize = 'small' 
            this.isSmallScreen = true;     
          }
          else if(
            result.breakpoints[Breakpoints.Medium] 
            // || result.breakpoints[Breakpoints.WebPortrait] 
          ){
            this.screenSize = 'medium' 
            this.isSmallScreen = false;     
          }      
          else if(
            result.breakpoints[Breakpoints.Large]
          ){
            this.screenSize = 'large' 
            this.isSmallScreen = false;     
          }         
      }
      else{
        this.screenSize = 'xlarge';
        this.isSmallScreen = false;      
      }
      // if (this.firstLoad) {
      //   this.firstLoad = false;
      // }
      //console.log("BreakPoint Service emitting => (screenSize, isSmallScreen)", this.screenSize, this.isSmallScreen);
      this.breakpointUpdate.emit(this.isSmallScreen)
    });

    

  }

}